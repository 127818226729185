@tailwind base;
@tailwind components;
@tailwind utilities;

@fant-face {
  font-family: "JetBrains_Mono";
  src: local("JetBrains_Mono"),
    url(fonts/JetBrains_Mono/static/JetBrainsMono-Regular.ttf) format("ttf");
}

body {
  margin: 0;
  font-family: "JetBrains_Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121214;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
